import { Button, Flex, HStack, InvertTheme } from '@stoplight/mosaic';
import React from 'react';

export const DemoNavbar = () => {
  return (
    <>
      <InvertTheme>
        <Flex h="3xl" shrink={0} px={5} alignItems="center" bg="canvas-pure">
          <HStack w="1/2" alignItems="center" spacing={4}>
            <a href="/">
              <img src="/logo.svg" alt="Share Of Model" />
            </a>
          </HStack>

          <Flex w="1/2" justifyContent="center"></Flex>

          <HStack w="1/3" flex={1} justifyContent="end">
            <Button as="a" appearance="minimal" target="__blank" href="https://shareofmodel.ai">
              Share Of Model
            </Button>
          </HStack>
        </Flex>
      </InvertTheme>
    </>
  );
};

